<template>
  <div class="404">
    <v-container>
      <h1 class="display-3 text-center my-16">{{ $t('main.404.title') }}</h1>
      <v-row justify="center">
        <v-col cols="12" xl="8">
          <lottie-player
            class="error-animation mx-auto"
            src="/json/404.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></lottie-player>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
@media only screen and (min-width: 960px) {
  .error-animation {
    width: 500px !important;
    height: 500px !important;
  }
}
</style>

<script>
export default {
  name: '404',
  data() {
    return {
    };
  },
};
</script>
